<template>
  <div class="settings pb-12">
    <shape>
      <template #header>
        <page-title>
          <template #icon>
            <ic-sunglasses :size="32" />
          </template>
          Admin
        </page-title>
      </template>
      <template #default>
        <div v-if="!isAdmin">You are not admin</div>
        <div v-else>
          <p>You are signed in as a admin and acts as the current account owner with a few exceptions.</p>
          <h3 class="mb-2">Admins can</h3>
          <ol class="list-disc ml-8">
            <li>access this page.</li>
            <li>sign in without having to complete the "additional signup"-form. (company info -> hubspot)</li>
            <li>change store subscription to pro/pro+ without adding a credit card.</li>
          </ol>
        </div>
      </template>
    </shape>
  </div>
</template>

<script>
import IcSunglasses from 'vue-material-design-icons/Sunglasses'
import { mapGetters } from 'vuex'

export default {
  name: 'admin',
  components: { IcSunglasses },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/getIsAdmin',
    }),
  },
}
</script>
